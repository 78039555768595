import React from "react";
import Layout from "../components/layout";
import Track404 from "../components/notFoundPages/trackNotFound";
import SEO from "../components/seo";
import artwork from "../images/icon.png";

function NotFoundPage() {
  const messages = [
    "Are you a hacker? Why are you trying to break my app? 😭",
    "You're not supposed to be here. Go back to the home page. 🏠",
    "I'm sorry, Dave. I'm afraid I can't do that. 🤖",
    "I'm not sure what you're looking for, but it's not here. 🤷‍♂️",
    "This is not the page you're looking for. 🙅‍♂️",
    "You're not supposed to see this... but I'm glad you're here. 😊",
    "You're not supposed to see this... or are you? 🤔",
  ];

  const randomMessage = messages[Math.floor(Math.random() * messages.length)];

  return (
    <Layout song={{ artist: "Error", title: "Page not found" }} artwork={artwork} showGrain>
      <SEO title={"404: Um... this is awkward"} />
      <Track404 song={{ artist: "", title: "" }} message={randomMessage} hideReleaseInfo />
    </Layout>
  );
}

export default NotFoundPage;
